import React from 'react'

const ContactInfo = () => {
    return (
        <>
            <div>
                <div className='contact'>
                    <p className='pb-4  fs-5' style={{ color: "var(--primary-color)!important" }}>Germany Office:</p>
                    <p>Truvest Capital Market GMBH </p>
                    <p>Mottmannstr. 1-3 53842 Troisdorf Germany</p>

                </div>
                <div className='contact py-3'>
                    <p className='fs-5' style={{ color: "var(--primary-color)!important" }}>Company Headquarter:</p>
                    <p>Sankt Augustin</p>

                </div>
                <div className='contact py-3 '>
                    <p className='lh-1 mb-1'>Registered Court:</p>
                    <p className='lh-1 mb-1'>District Court </p>
                    <p className='lh-1 mb-1'>Siegburg, HRB 15784</p>
                </div>
                <div className='contact py-3 '>
                    <p>Phone: +49 2241 324 3012 (Germany)</p>
                    <p>Phone: +44 (0)20 7078 7132 (UK)</p>
                </div>

            </div>
        </>
    )
}

export default ContactInfo