import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
    const initialFormData = {
        fullName: '',
        email: '',
        phone: '',
        message: ''
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if any field is empty
        const isFormValid = Object.values(formData).every(value => value.trim() !== '');
        if (!isFormValid) {
            toast.error("Please fill all the fields.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        const toastId = toast.loading("Sending...");

        const serviceID = 'service_7ihi0ws';
        const templateID = 'template_9iz5tye';
        const userID = 'ad3JI7bkSUQP-_GRyLEpO';

        emailjs.send(serviceID, templateID, formData, userID)
            .then((response) => {
                toast.update(toastId, { 
                    render: "Message sent successfully!", 
                    type: "success", 
                    isLoading: false, 
                    autoClose: 2000 
                });
                console.log('SUCCESS!', response.status, response.text);
                setFormData(initialFormData);
            }, (error) => {
                toast.update(toastId, { 
                    render: "Something went wrong. Please try again.", 
                    type: "error", 
                    isLoading: false, 
                    autoClose: 2000 
                });
                console.log('FAILED...', error);
            });
    };

    return (
        <>
            <div className='contact-form'>
                <p>Please Fill Out the Form Below, for General Inquiries.</p>
                <Form onSubmit={handleSubmit}>
                    <Row className='mb-4'>
                        <Col xs={2}>
                            <Form.Label className='mt-3'>FULL NAME <span>*</span></Form.Label>
                        </Col>
                        <Col xs={10}>
                            <Form.Control
                                type="text"
                                placeholder="Enter your full name"
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col xs={2}>
                            <Form.Label className='mt-3'>EMAIL <span>*</span></Form.Label>
                        </Col>
                        <Col xs={10}>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col xs={2}>
                            <Form.Label className='mt-3'>PHONE <span>*</span></Form.Label>
                        </Col>
                        <Col xs={10}>
                            <Form.Control
                                type="text"
                                placeholder="Enter your phone number"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col xs={2}>
                            <Form.Label className='mt-1'>MESSAGE <span>*</span></Form.Label>
                        </Col>
                        <Col xs={10}>
                            <Form.Control
                                as="textarea"
                                placeholder='Message'
                                rows={6}
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col xs={2}></Col>
                        <Col xs={10}>
                            <Button type="submit" className='mt-2'>Submit</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <ToastContainer />
        </>
    );
}

export default ContactForm;
