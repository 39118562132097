import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const CookieConsent = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setTimeout(() => {
                
                setShow(true); 
            }, 2000);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setShow(false);
    };

    const handleReject = () => {
        localStorage.setItem('cookieConsent', 'rejected');
        setShow(false);
    };

    return (
        <div className={`cookie-modal-wrapper ${show ? 'slide-in' : 'slide-out'}`}>
            <div className="cookie-modal-content">
                <h5 className="text-center mb-3">Cookie Preferences</h5>
                <p className="text-center">
                    Truvest Capital Market uses cookies to enhance your browsing experience. Please choose your preference below.
                </p>
                <div className="d-flex justify-content-end gap-3">
                    <Button variant="outline-danger" onClick={handleReject}>
                        Reject
                    </Button>
                    <Button variant="outline-success" onClick={handleAccept}>
                        Accept
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;
