import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DynamicHero from '../components/shared/DynamicHero';

const Vedios = () => {
    const cards = [
        {
            video: '/vedios/vedio1.mp4',
            heading: "Partner with Us to Drive Global Financial Growth",
            text: "Join Truvest Capital Markets in shaping the future of trade and supply chain finance. By contributing to our proven financial solutions, you’ll play a pivotal role in enabling businesses worldwide to access liquidity, expand operations, and succeed in international markets. Discover how your participation can generate secure and meaningful returns while supporting innovative financial systems.",
        },
        {
            video: '/vedios/vedio2.mp4',
            heading: "Supply Chain Finance",
            text: "Discover the essentials of supply chain finance with Truvest Capital Markets. In this video, we break down how this powerful financial solution helps businesses optimize cash flow and ensure seamless operations across their supply chains. Whether you're a buyer, seller, or financial partner, learn how Truvest provides secure and profitable solutions tailored to your needs. Perfect for anyone new to supply chain finance—watch now and gain valuable insights!",
            reverse: true
        },
        {
            video: '/vedios/vedio3.mp4',
            heading: "Financial Instruments",
            text: "Join Truvest Capital Markets as we simplify the concept of financial instruments and their role in trade finance. This video explores how instruments like MT760 and MT799 are issued through the SWIFT platform, their use in securing borrowing, project funding, and no-recourse loans. Perfect for anyone looking to understand these essential financial tools and how they drive global trade. Watch now for a clear and professional overview!",
        },
        {
            video: '/vedios/vedio4.mp4',
            heading: "Issuance of Financial Instruments",
            text: `Explore how Truvest Capital Markets issues financial instruments to support international trade, project financing, and liquidity provision. This video clarifies the process, dispels myths about "leased" instruments, and introduces our unique "use - of - period" approach. Gain insights into SWIFT messaging, bank communications, and the strategies Truvest uses to ensure secure and successful transactions. Watch now for a professional and simplified overview!`,
            reverse: true
        },
        {
            video: '/vedios/vedio5.mp4',
            heading: "Monetization and Funding of Financial Instruments",
            text: "Discover how Truvest Capital Markets transforms financial instruments into cash or credit through monetization. This video explains the processes of discounting, funding, warehousing, and negotiating claims with banks. Perfect for those new to these concepts, learn how Truvest strategically utilizes these methods to generate profits for clients, financial partners, and itself. Watch now for a clear and professional breakdown!",
        },
        {
            video: '/vedios/vedio6.mp4',
            heading: "Trade Finance",
            text: `Curious about trade finance but not sure where to start? This video by Truvest Capital Markets breaks down the basics of trade finance in simple terms. Learn what it is, how it works, and why it’s essential for businesses involved in global trade. Perfect for beginners looking to understand the fundamentals. Watch now and gain clarity on how trade finance can support international transactions!`,
            reverse: true
        },
    ];

    return (
        <>
            <DynamicHero
                heading={<p className="display-4 text-uppercase">Videos of <br /> Transactions</p>}
                transaction
            />
            <Container className="my-5 vedios">
                {cards.map((card, index) => (
                    <Row
                        key={index}
                        className={`align-items-center mb-5 ${card.reverse ? 'flex-row-reverse' : ''}`}
                    >
                        <Col lg={6} xs={12} className={` ${card.reverse ? 'text-lg-end ps-xxl-5' : 'pe-xxl-5'}`}>
                            <h3 className="mb-3 color-primary fw-normal display-6 text-uppercase">{card.heading}</h3>
                            <p>{card.text}</p>
                        </Col>
                        <Col lg={6} xs={12} className="d-flex align-items-center" style={{ height: '100%' }}>
                            <video
                                src={card.video}
                                controls
                                className="w-100"
                                style={{ height: '100%' }}
                            />
                        </Col>
                    </Row>
                ))}
            </Container>
        </>
    );
};

export default Vedios;
