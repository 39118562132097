import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import logo from "../../assets/logo.png";
import { Image } from 'react-bootstrap';
import { FaAngleDown } from "react-icons/fa6";

function Header() {
  const [show, setShow] = useState(false);
  const [activeOffcanvasDropdown, setActiveOffcanvasDropdown] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [hoveredDropdown, setHoveredDropdown] = useState(null);

  const navItems = [
    { path: "/", label: "Home" },
    {
      label: "Services",
      dropdown: [
        { path: "/service1", label: "Capital Contribution" },
        { path: "/service2", label: "Contributors & Truvest Fintech Banking Services" },
        { path: "/service3", label: "International Trade Financing" },
      ],
    },
    { path: "/contact", label: "Contact" },
    {
      label: "Transaction",
      dropdown: [
        { path: "/transaction", label: "Financial Intrument Issuance" },
        { path: "/transaction2", label: "Precious Metals" },
        { path: "/transaction3", label: "Petroleum & Petrochemicals" },
        { path: "/transaction4", label: "Other Goods" },
        { path: "/videos", label: "Corporate Videos" },
      ],
    },
    { path: "https://banking.truvest.de/en/Identity/Account/Login/?ReturnUrl=/", label: "Login/New Account", login: true },
  ];

  return (
    <>
      {/* Navbar */}
      <Navbar expand="lg" className="bg-white py-3">
        <Container>
          <NavLink to="/">
            <Image src={logo} alt="Logo" height={58} />
          </NavLink>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleShow} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto d-none d-lg-flex">
              {navItems.map((item, index) => (
                item.dropdown ? (
                  <Dropdown
                    key={index}
                    className="mx-xl-3 ms-1"
                    onMouseEnter={() => setHoveredDropdown(index)}
                    onMouseLeave={() => setHoveredDropdown(null)}
                    show={hoveredDropdown === index}
                  >
                    <Dropdown.Toggle
                      as="span"
                      className="mx-xl-3 ms-1 mt-2 nav-link text-uppercase dropdown-toggle header-nav-link e-caret-none"
                      style={{ cursor: "pointer" }}
                    >
                      {item.label} <FaAngleDown className="ms-1 mb-1 fs-6" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='navbar-dropdown '>
                      {item.dropdown.map((subItem, subIndex) => (
                        <Dropdown.Item key={subIndex} as={NavLink} to={subItem.path} className='text-wrap'>
                          {subItem.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <NavLink
                    className={({ isActive }) =>
                      "mx-xl-3 ms-1 mt-2 nav-link text-uppercase header-nav-link" + (isActive ? ' active' : '')
                    }
                    key={index}
                    to={item.path}
                  >
                    {item.label}
                  </NavLink>
                )
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Offcanvas */}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="mt-1"> <Image src={logo} alt="Logo" height={40} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="px-0">
          <Nav className="flex-column">
            {navItems.map((item, index) => (
              item.dropdown ? (
                <div key={index} className="py-3 nav-link text-uppercase custom-nav-link">
                  <span
                    className="text-uppercase custom-nav-link dropdown-header"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setActiveOffcanvasDropdown(
                        activeOffcanvasDropdown === index ? null : index
                      )
                    }
                  >
                    {item.label}
                    <FaAngleDown className="ms-1 mb-1 fs-6" />
                  </span>
                  {activeOffcanvasDropdown === index && (
                    <div className="ms-3">
                      {item.dropdown.map((subItem, subIndex) => (
                        <NavLink
                          className={({ isActive }) =>
                            "py-3 nav-link text-uppercase custom-nav-link" + (isActive ? " active" : "")
                          }
                          key={subIndex}
                          to={subItem.path}
                          onClick={handleClose}
                        >
                          {subItem.label}
                        </NavLink>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <NavLink
                  className={({ isActive }) =>
                    "py-3 nav-link text-uppercase custom-nav-link" + (isActive ? " active" : "")
                  }
                  key={index}
                  to={item.path}
                  onClick={handleClose}
                >
                  {item.label}
                </NavLink>
              )
            ))}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Header;
