import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
const DynamicHero = ({ heading, small, description, contact, transaction, service, home, upperheading, br }) => {
    const navigate = useNavigate()
    return (
        <>
            <div className={`py-5 ${home ? "homeBg" : contact ? 'contactbg' : transaction ? 'transactionbg' : service ? 'servicebg' : ""}`}>
                <Container className='text-white'>
                    <Row>
                        <Col xs={12} className='d-flex align-items-center py-5'>
                            <div className='text-center text-lg-start'>
                                <div className="text-white text-uppercase mb-3" style={{ letterSpacing: "5px" }}>
                                    {upperheading}
                                </div>
                                {heading}
                                {small ?
                                    <div class="separator py-2">
                                        <div class="line d-lg-none d-block"></div>
                                        <small className='pe-2 ps-lg-0 ps-2'>{small}</small>
                                        <div class="line"></div>
                                    </div>
                                    : ""}

                                {description ?
                                    <p className={`fs-5 ${br ? "col-lg-8" : ""}`}>{description}</p> : ""
                                }
                                {
                                    home ? <>
                                        <Button onClick={() => navigate('/booking')} className='border-0   custom-btn'>
                                            Appointment
                                        </Button>
                                    </> : ""
                                }

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default DynamicHero