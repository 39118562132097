import React from 'react';
import { Button, Container } from 'react-bootstrap';
import PageHeading from '../components/shared/PageHeading';
import { useNavigate } from 'react-router-dom';

const BookingPage = () => {
    // console.log(process.env.REACT_APP_BOOKING_KEY)
    const navigate = useNavigate();
    const handleBookingRedirect = () => {
        navigate(-1);
    };
    return (
        <>

            <Container className='py-5'>
                <Button
                    onClick={handleBookingRedirect}
                    style={{ backgroundColor: "#253571" }}
                    className="mb-2"
                >
                    Go Back
                </Button>

                <PageHeading heading="Book your appointment" />
                <h1 className="text-uppercase fs-4 color-primary service-card mb-4">
                    Please fill up the calendar and book your appointment now!
                </h1>
                <div className="calendar-container mt-4">
                    <iframe
                        src={process.env.REACT_APP_BOOKING_KEY}
                        style={{
                            border: 'solid 1px #777',
                            width: '100%',
                            height: '750px'
                        }}
                        frameBorder="0"
                        scrolling="no"
                        title="Book Appointment"
                    ></iframe>
                </div>
            </Container>
        </>

    );
};

export default BookingPage;

