import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { CountrySelect } from "react-country-state-city";
import DatePicker from "react-datepicker";
import { FileIcons } from "../assets/SVG";

const IdentificationForm = ({ formData, onInputChange }) => {
    const handleFileUpload = (key, e) => {
        const file = e.target.files[0];
        if (!file) return;

        const fileSizeLimit = 5 * 1024 * 1024; 
        const allowedImageTypes = ["image/jpeg", "image/png"];
        const isImage = allowedImageTypes.includes(file.type);

        if (file.size > fileSizeLimit) {
            alert("File size exceeds 5MB limit.");
            return;
        }

        const fileData = {
            name: file.name,
            type: isImage ? "image" : "file",
            file, 
        };

        if (isImage) {
            fileData.url = URL.createObjectURL(file);
        }

        onInputChange(key, fileData); 
    };


    return (
        <div>
            <h5 className="fw-bold mb-4">3. Identification Documents</h5>

            {/* Primary Identification */}
            <Row className="mb-4 align-items-center">
                <Col md={3}>
                    <Form.Label>Primary Identification</Form.Label>
                </Col>
                <Col md={9}>
                    <div
                        className="file-uploader pointer col-lg-5 col-12 d-flex align-items-center justify-content-center text-center p-2 border rounded bg-light cursor-pointer"
                        onClick={() => document.getElementById("IdentificationDocsFile1").click()}
                    >
                        <span className="me-2">
                            <FileIcons />
                        </span>
                        <span>Upload File</span>
                    </div>
                    <Form.Control
                        type="file"
                        id="IdentificationDocsFile1"
                        className="d-none"
                        onChange={(e) => handleFileUpload("IdentificationDocsFile1", e)}
                    />
                    <small className="text-muted d-block mt-2">
                        (Maximum Size of JPG/PNG should be 5.0 MB Dimension: 413 px x 531 px)
                    </small>
                    {formData.IdentificationDocsFile1 && formData.IdentificationDocsFile1.type === "image" && (
                        <img
                            src={formData.IdentificationDocsFile1.url}
                            alt="Uploaded Preview"
                            className="img-fluid mt-3"
                            style={{ maxWidth: "200px", maxHeight: "200px", objectFit: "cover" }}
                        />
                    )}
                    {formData.IdentificationDocsFile1 && formData.IdentificationDocsFile1.type === "file" && (
                        <p className="mt-3 text-muted">
                            Uploaded File: <strong>{formData.IdentificationDocsFile1.name}</strong>
                        </p>
                    )}
                </Col>
            </Row>

            {/* Identification Type */}
            <Row className="mb-4 align-items-center">
                <Col md={3}>
                    <Form.Label>Type</Form.Label>
                </Col>
                <Col md={9} className="d-flex">
                    <Form.Check
                        type="radio"
                        name="PIType"
                        value="Passport"
                        label="Passport"
                        id="Passport"
                        checked={formData.IdentificationDocsType === "Passport"}
                        onChange={(e) => onInputChange("IdentificationDocsType", e.target.value)}
                        className="me-3"
                        required
                    />
                    <Form.Check
                        type="radio"
                        name="PIType"
                        value="National ID"
                        label="National ID"
                        id="NationalID"
                        checked={formData.IdentificationDocsType === "National ID"}
                        onChange={(e) => onInputChange("IdentificationDocsType", e.target.value)}
                        className="me-3"
                        required
                    />
                    <Form.Check
                        type="radio"
                        name="PIType"
                        value="Driver’s License"
                        label="Driver’s License"
                        id="DriversLicense"
                        checked={formData.IdentificationDocsType === "Driver’s License"}
                        onChange={(e) => onInputChange("IdentificationDocsType", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            {/* Identification Number */}
            <Row className="mb-4 align-items-center">
                <Col md={3}>
                    <Form.Label>Number</Form.Label>
                </Col>
                <Col md={9}>
                    <Form.Control
                        name="PINumber"
                        type="number"
                        placeholder="Enter Number"
                        value={formData.IdentificationDocsNumber}
                        onChange={(e) => onInputChange("IdentificationDocsNumber", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            {/* Issuing Country */}
            <Row className="mb-4 align-items-center">
                <Col md={3}>
                    <Form.Label>Issuing Country</Form.Label>
                </Col>
                <Col md={9}>
                    <CountrySelect
                        inputClassName={"border-0"}
                        placeHolder="Select Country"
                        required
                        value={formData.IdentificationDocsIssuingCountry}
                        onChange={(value) => onInputChange("IdentificationDocsIssuingCountry", value?.name)}
                    />
                </Col>
            </Row>

            {/* Issue and Expiry Dates */}
            <Row>
                <Col>
                    <Row className="mb-4 align-items-center">
                        <Col md={6}>
                            <Form.Label>Issue Date</Form.Label>
                        </Col>
                        <Col md={6}>
                            <DatePicker
                                selected={formData.IdentificationDocsIssueDate}
                                onChange={(date) => onInputChange("IdentificationDocsIssueDate", date)}
                                className="form-control"
                                placeholderText="DD/MM/YYYY"
                                dateFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                required
                            />
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="mb-4 align-items-center">
                        <Col md={6}>
                            <Form.Label>Expiry Date</Form.Label>
                        </Col>
                        <Col md={6}>
                            <DatePicker
                                selected={formData.IdentificationDocsExpiryDate}
                                onChange={(date) => onInputChange("IdentificationDocsExpiryDate", date)}
                                className="form-control "
                                
                                placeholderText="DD/MM/YYYY"
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                required
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* Secondary File Upload */}
            <Row className="mb-4 align-items-center">
                <Col md={3}>
                    <Form.Label>Upload</Form.Label>
                </Col>
                <Col md={9}>
                    <div
                        className="file-uploader pointer col-lg-5 col-12 d-flex align-items-center justify-content-center text-center p-2 border rounded bg-light cursor-pointer"
                        onClick={() => document.getElementById("IdentificationDocsFile2").click()}
                    >
                        <span className="me-2">
                            <FileIcons />
                        </span>
                        <span>Upload File</span>
                    </div>
                    <Form.Control
                        type="file"
                        id="IdentificationDocsFile2"
                        className="d-none"
                        onChange={(e) => handleFileUpload("IdentificationDocsFile2", e)}
                    />
                    <small className="text-muted d-block mt-2">
                        (Maximum Size of JPG/PNG should be 5.0 MB Dimension: 413 px x 531 px)
                    </small>
                    {formData.IdentificationDocsFile2 && formData.IdentificationDocsFile2.type === "image" && (
                        <img
                            src={formData.IdentificationDocsFile2.url}
                            alt="Uploaded Preview"
                            className="img-fluid mt-3"
                            style={{ maxWidth: "200px", maxHeight: "200px", objectFit: "cover" }}
                        />
                    )}
                    {formData.IdentificationDocsFile2 && formData.IdentificationDocsFile2.type === "file" && (
                        <p className="mt-3 text-muted">
                            Uploaded File: <strong>{formData.IdentificationDocsFile2.name}</strong>
                        </p>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default IdentificationForm;
