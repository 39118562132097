import React from 'react'
import DynamicHero from '../components/shared/DynamicHero'
import { Col, Container, Image, Row } from 'react-bootstrap'
import PageHeading from '../components/shared/PageHeading'
import img1 from '../assets/Transaction1.png'
import { Link } from 'react-router-dom'

const Transaction = () => {
    return (
        <>
            <DynamicHero
                heading={<p className="display-4 text-uppercase">Financial<br />Instrument Issuance</p>}
                transaction
            />
            <Container className="my-5">
                <PageHeading heading="Transactions" />
                <Row className="align-items-center gy-4 py-3">
                    <Col lg={6} md={12}>
                        <h1 className="text-uppercase fs-4 color-primary service-card mb-4">
                            Financial Instrument Issuance
                        </h1>
                        <p>
                            As a valued client successfully onboarded with Truvest Capital, you will gain access to
                            opportunities for participation in the issuance of high-value financial instruments.
                        </p>
                        <p>
                            Truvest provides flexible and tailored financial instrument issuance solutions to support
                            verified corporations with their lucrative trade finance needs.
                        </p>
                        <p>
                            Our team of experts will work with large corporates who have a proven track record in respect
                            of their underlying trade finance requirement.
                        </p>
                        <p>
                            To secure the ideal financial instrument that meets their specific needs, Truvest will secure
                            a handsome percentage of the anticipated profit in the underlying transaction, providing a
                            fixed equity stake for our clients, securing our clients' participation towards the issuance
                            of the required financial instrument.
                        </p>
                        <p>
                            Our corporate partners access the financial instruments they need to grow and expand their
                            business, and our clients have the peace of mind entering into a 100% secure transaction
                            with a fixed equity stake in the underlying profit.
                        </p>
                        <p>Whether you are an individual or a corporation requiring the issuance or monetization of a financial instrument, Truvest Capital Market is here to provide expert assistance. Simply click Issuance or Monetization button to complete the relevant form, and one of our experienced Account Executives will promptly reach out to guide you through the next steps.</p>
                        <Link className='btn custom-btn text-white' to="/issuance">Issuance</Link>
                    </Col>
                    <Col lg={6} md={12} className="d-flex justify-content-center">
                        <Image fluid alt="Transaction Image" src={img1} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Transaction
