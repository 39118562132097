import React from 'react'
import DynamicHero from '../components/shared/DynamicHero'
import { Col, Container, Image, Row } from 'react-bootstrap'
import PageHeading from '../components/shared/PageHeading'
import img1 from '../assets/transaction3.png'

const Transaction = () => {
    return (
        <>
            <DynamicHero
                heading={<p className="display-4 text-uppercase">Petroleum & <br />Petrochemicals</p>}
                transaction
            />
            <Container className="my-5">
                <PageHeading heading="Transactions" />
                <Row className="align-items-center gy-4 py-3">
                    <Col lg={6} md={12}>
                        <h1 className="text-uppercase fs-4 color-primary service-card mb-4">
                            Petroleum & Petrochemicals
                        </h1>
                        <p>
                           Truvest oversee and optimise the delivery logistics and sales of petroleum and petrochemical products, on behalf of our verified petroleum partners. By strategically deploying these resources, we aim to enhance efficiency and reliability throughout the supply chain, ensuring timely and secure transactions. This approach not only strengthens our global presence but also safeguards our clients and partners, fostering mutual growth and success.     </p>
                       
                        <p>
                           Our strategy also involves securing funding through financial instruments, such as Bank Guarantees, Standby Letters of Credit and Documentary Letters of Credits issued by the purchasers of the products. These instruments serve as a guarantee for the transactions, providing an added layer of security for our partners. Additionally, our partners hold an equity stake in the profits generated from the sale of goods. This ensures that they directly benefit from the success of our operations.    </p>
                        <p>To further mitigate risks, we have implemented comprehensive credit insurance policies through third-party insurance brokers. These policies act as safeguards in the event of non-performance or transaction failure, offering financial protection against potential losses. By proactively managing risks and leveraging financial instruments and insurance solutions, we aim to uphold the integrity of our investments and maximize returns for our partners.</p>
                    </Col>
                    <Col lg={6} md={12} className="d-flex justify-content-center">
                        <Image fluid alt="Transaction Image" src={img1} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Transaction
