import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FileIcons } from "../assets/SVG";

const SupportinDocsForm = ({ formData, onInputChange }) => {
    const handleFileUpload = (key, e) => {
        const file = e.target.files[0];
        if (!file) return;

        const fileSizeLimit = 5 * 1024 * 1024;
        const allowedImageTypes = ["image/jpeg", "image/png"];
        const isImage = allowedImageTypes.includes(file.type);

        if (file.size > fileSizeLimit) {
            alert("File size exceeds 5MB limit.");
            return;
        }

        const fileData = {
            name: file.name,
            type: isImage ? "image" : "file",
            file,
        };

        if (isImage) {
            fileData.url = URL.createObjectURL(file);
        }

        onInputChange(key, fileData);
    };
    const renderFileUploader = (label, key) => (
        <Row className="mb-4 align-items-center">
            <Col md={3}>
                <Form.Label>{label}</Form.Label>
            </Col>
            <Col md={9}>
                <div
                    className="file-uploader pointer col-lg-5 col-12 d-flex align-items-center justify-content-center text-center p-2 border rounded bg-light cursor-pointer"
                    onClick={() => document.getElementById(key).click()}
                >
                    <span className="me-2">
                        <FileIcons />
                    </span>
                    <span>Upload File</span>
                </div>
                <Form.Control
                    type="file"
                    id={key}
                    className="d-none"
                    onChange={(e) => handleFileUpload(key, e)}
                />
                <small className="text-muted d-block mt-2">
                    (Maximum Size of JPG/PNG should be 5.0 MB Dimension: 413 px x 531 px)
                </small>
                {formData[key] && formData[key].type === "image" && (
                    <img
                        src={formData[key].url}
                        alt="Uploaded Preview"
                        className="img-fluid mt-3"
                        style={{ maxWidth: "200px", maxHeight: "200px", objectFit: "cover" }}
                    />
                )}
                {formData[key] && formData[key].type === "file" && (
                    <p className="mt-3 text-muted">
                        Uploaded File: <strong>{formData[key].name}</strong>
                    </p>
                )}
            </Col>
        </Row>
    );

    return (
        <div>
            <h5 className="fw-bold mb-4">8. Supporting Documents</h5>
            {renderFileUploader("Proof of Address (e.g., utility bill, bank statement)", "SupportingDocsProofOfAddress")}
            {renderFileUploader("Financial Statements (If applicable)", "SupportingDocsFinancialStatements")}
            {renderFileUploader("Business Plan or Project Details (If applicable)", "SupportingDocsBusinessPlan")}
        </div>
    );
};

export default SupportinDocsForm;
