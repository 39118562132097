import { useState } from "react";
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps";

const geoUrl = "https://raw.githubusercontent.com/datasets/geo-boundaries-world-110m/master/countries.geojson";

const officeLocations = [
    { name: "Barcelona (Spain)", coordinates: [2.1734, 41.3851] },
    { name: "Dubai", coordinates: [55.296249, 25.276987] },
    { name: "Wyoming (USA)", coordinates: [-107.290284, 43.075968] },
    { name: "Troisdorf (Germany)", coordinates: [7.1497, 45.8121] },
    { name: "Egham (UK)", coordinates: [-0.5489, 51.4307] },
];

const transactionLocations = [
    // General
    { name: "Dubai", coordinates: [55.296249, 25.276987] },
    { name: "China", coordinates: [104.1954, 35.8617] },
    { name: "Ghana", coordinates: [-1.0232, 7.9465] },
    { name: "Nigeria", coordinates: [8.6753, 9.082] },
    { name: "South Africa", coordinates: [22.9375, -30.5595] },
    { name: "Abu Dhabi", coordinates: [54.3773, 24.4539] },
    { name: "Fujairah", coordinates: [56.3417, 25.1283] },
    { name: "Libya", coordinates: [17.2283, 26.3351] },
    { name: "Barcelona", coordinates: [2.1734, 41.3851] },
    { name: "London", coordinates: [-0.1276, 51.5074] },
    { name: "Kalay", coordinates: [94.0163, 23.1923] },
    { name: "Rotterdam", coordinates: [4.47917, 51.9225] },
    { name: "Antwerp", coordinates: [4.4025, 51.2194] },
    { name: "Hamburg", coordinates: [9.9937, 53.5511] },
    { name: "Le Havre", coordinates: [0.1079, 49.4944] },
    { name: "Felixstowe", coordinates: [1.3508, 51.9612] },
    { name: "Valencia", coordinates: [-0.3763, 39.4699] },
    { name: "Piraeus", coordinates: [23.6471, 37.9421] },
    { name: "Algeciras", coordinates: [-5.4419, 36.1408] },
    { name: "Bremerhaven", coordinates: [8.5859, 53.5396] },

    // US
    { name: "Los Angeles", coordinates: [-118.2437, 34.0522] },
    { name: "Long Beach", coordinates: [-118.1937, 33.7701] },
    { name: "New York and New Jersey", coordinates: [-74.006, 40.7128] },
    { name: "Savannah", coordinates: [-81.0912, 32.0809] },
    { name: "Northwest Seaport Alliance", coordinates: [-122.4194, 47.6062] },
    { name: "Houston", coordinates: [-95.3698, 29.7604] },
    { name: "Seattle", coordinates: [-122.3321, 47.6062] },
    { name: "Tacoma", coordinates: [-122.4443, 47.2529] },
    { name: "Norfolk", coordinates: [-76.2859, 36.8508] },
    { name: "Charleston", coordinates: [-79.9471, 32.7765] },
    { name: "Oakland", coordinates: [-122.2711, 37.8044] },
    { name: "Miami", coordinates: [-80.1918, 25.7617] },
    { name: "Baltimore", coordinates: [-76.6122, 39.2904] },
    { name: "Port Everglades", coordinates: [-80.1365, 26.0899] },
    { name: "Philadelphia", coordinates: [-75.1652, 39.9526] },
    { name: "New Orleans", coordinates: [-90.0715, 29.9511] },
    { name: "San Juan", coordinates: [-66.1057, 18.4655] },
    { name: "Free Port", coordinates: [-78.7011, 26.5285] },
    { name: "Vancouver", coordinates: [-123.1216, 49.2827] },
    { name: "Montreal", coordinates: [-73.5673, 45.5017] },
    { name: "Prince Rupert", coordinates: [-130.3208, 54.3151] },
    { name: "Halifax", coordinates: [-63.5752, 44.6488] },
    { name: "Saint John", coordinates: [-66.057, 45.2733] },

    // Asia
    { name: "Shanghai", coordinates: [121.4737, 31.2304] },
    { name: "Ningbo-Zhoushan", coordinates: [121.5498, 29.8683] },
    { name: "Shenzhen", coordinates: [114.0579, 22.5431] },
    { name: "Qingdao", coordinates: [120.3826, 36.0671] },
    { name: "Busan", coordinates: [129.0756, 35.1796] },
    { name: "Singapore", coordinates: [103.8198, 1.3521] },
    { name: "Jebel Ali", coordinates: [55.0155, 25.0483] },
    { name: "Hong Kong", coordinates: [114.1694, 22.3193] },
    { name: "Klang", coordinates: [101.4699, 3.0432] },
    { name: "Tokyo", coordinates: [139.6917, 35.6895] },
    { name: "Yokohama", coordinates: [139.638, 35.4437] },
    { name: "Osaka", coordinates: [135.5023, 34.6937] },
    { name: "Chiba", coordinates: [140.1233, 35.6073] },
    { name: "Nagoya", coordinates: [136.9066, 35.1815] },
    { name: "Mumbai", coordinates: [72.8777, 19.076] },
    { name: "Tanjung", coordinates: [110.3802, 1.5581] },
    { name: "Bangkok", coordinates: [100.5018, 13.7563] },

    // Oceania
    { name: "Melbourne", coordinates: [144.9631, -37.8136] },
    { name: "Botany (Sydney)", coordinates: [151.2093, -33.8688] },
    { name: "Brisbane", coordinates: [153.0281, -27.4679] },
    { name: "Tauranga", coordinates: [176.1674, -37.6861] },
    { name: "Auckland", coordinates: [174.7633, -36.8485] },
    { name: "Fremantle", coordinates: [115.7499, -32.0569] },
    { name: "Lyttleton", coordinates: [172.7228, -43.6034] },
    { name: "Adelaide", coordinates: [138.6007, -34.9285] },
    { name: "Napier", coordinates: [176.8713, -39.4928] },
    { name: "Otago", coordinates: [170.5036, -45.8788] },

    // Africa
    { name: "Mombasa", coordinates: [39.6682, -4.0435] },
    { name: "Suez", coordinates: [32.5339, 29.9668] },
    { name: "Durban", coordinates: [31.0218, -29.8587] },
    { name: "Lagos", coordinates: [3.3792, 6.5244] },
    { name: "Dar-es-Salaam", coordinates: [39.2083, -6.7924] },
    { name: "Abidjan", coordinates: [-3.9833, 5.3599] },
    { name: "Beira", coordinates: [34.8343, -19.8436] },
    { name: "Tanger Med", coordinates: [-5.9148, 35.7282] },
    { name: "Walvis Bay", coordinates: [14.5086, -22.9576] },
    { name: "Djibouti", coordinates: [43.1505, 11.8251] },
    { name: "Said", coordinates: [32.2748, 31.2606] },
    { name: "Djen Djen", coordinates: [6.3628, 36.8087] },
    { name: "Berbera", coordinates: [45.013, 10.4357] },
    { name: "Conakry", coordinates: [-13.7122, 9.6412] },
    { name: "Dakar", coordinates: [-17.4448, 14.7167] },
    { name: "Matadi", coordinates: [13.451, -5.8459] },

    // Latin America
    { name: "Buenos Aires", coordinates: [-58.3816, -34.6037] },
    { name: "Rio de Janeiro", coordinates: [-43.1729, -22.9068] },
    { name: "Santos", coordinates: [-46.3289, -23.9503] },
    { name: "Colon", coordinates: [-79.9038, 9.3564] },
    { name: "Manzanillo", coordinates: [-104.3257, 19.0523] },
    { name: "Balboa", coordinates: [-79.5489, 8.9516] },
    { name: "Lázaro Cárdenas", coordinates: [-102.1966, 17.9587] },
    { name: "Callao", coordinates: [-77.1426, -12.0556] },
    { name: "Guayaquil", coordinates: [-79.8956, -2.1896] },
    { name: "Cartagena", coordinates: [-75.5144, 10.3915] },
    { name: "Valparaiso", coordinates: [-71.6295, -33.0472] },
    { name: "Montevideo", coordinates: [-56.1645, -34.9011] }
];


const GeoChartWithMarkers = () => {
    const [hoveredCountry, setHoveredCountry] = useState(null);

    return (
        <div className="map-container">
            <ComposableMap projectionConfig={{ scale: 160 }}>
                <ZoomableGroup zoom={1} minZoom={1} maxZoom={1}>
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            geographies
                                .filter((geo) => geo.properties.name !== "Antarctica")
                                .map((geo) => (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        style={{
                                            default: { fill: "#253571", stroke: "#0055A4" },
                                            hover: { fill: "#253570", outline: "none" },
                                            pressed: { fill: "#FFA500", outline: "none" },
                                        }}
                                        onMouseEnter={() => {
                                            const { NAME } = geo.properties;
                                            setHoveredCountry(NAME);
                                        }}
                                        onMouseLeave={() => setHoveredCountry(null)}
                                    />
                                ))
                        }
                    </Geographies>
                    {transactionLocations.map(({ name, coordinates }, index) => (
                        <Marker
                            key={`transaction-${index}`}
                            coordinates={coordinates}
                            onMouseEnter={() => setHoveredCountry(name)}
                            onMouseLeave={() => setHoveredCountry(null)}
                        >
                            <circle r={2} fill="#B49F8B" style={{ cursor: "pointer" }} />
                            <text
                                textAnchor="middle"
                                y={-10}
                                style={{
                                    fontSize: "8px",
                                    fill: "#B49F8B",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.8)",
                                }}
                            >
                                {hoveredCountry === name ? name : ""}
                            </text>
                        </Marker>
                    ))}
                    {officeLocations.map(({ name, coordinates }, index) => (
                        <Marker onMouseEnter={() => setHoveredCountry(name)}
                            onMouseLeave={() => setHoveredCountry(null)}
                            key={`office-${index}`} coordinates={coordinates}>
                            <image
                                href="/logo.svg"
                                height="15"
                                width="15"
                                style={{
                                    filter: "drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.5))",
                                    cursor: "pointer",
                                    position: "relative",
                                    zIndex: "9999!important",
                                }}

                            />
                            <text
                                textAnchor="middle"
                                y={10}
                                style={{
                                    fontSize: "8px",
                                    fill: "#42DACE",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.8)",
                                }}
                            >
                                {hoveredCountry === name ? name : ""}
                            </text>
                        </Marker>
                    ))}
                </ZoomableGroup>
            </ComposableMap>
        </div>
    );
};

export default GeoChartWithMarkers;
