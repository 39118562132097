import React from 'react';
import { HomeIcon1, HomeIcon2, HomeIcon3, HomeIcon4 } from '../../assets/SVG';
import { Container, Row, Col } from 'react-bootstrap';

const HomeCards = () => {
    const cards = [
        { icon: HomeIcon1, heading: "540+", text: "Introductory Transaction Services (ITS)" },
        { icon: HomeIcon2, heading: "350+", text: "Discretionary Transaction Portfolio Services (DTPS)" },
        { icon: HomeIcon3, heading: "510+", text: "Managed Transaction Portfolio Service (MTPS)" },
        {
            icon: HomeIcon4, heading: "1000+", text: (
                <>
                    Number of <br /> Transactions
                </>
            )
        },
    ];

    return (
        <div className="py-5" style={{ backgroundColor: "#253571" }}>
            <Container>
                <Row className="g-4">
                    {cards.map((card, index) => (
                        <Col key={index} lg={3} md={6} xs={12}>
                            <div
                                className="p-4 text-center rounded"

                            >
                                <div className="mb-3">
                                    <card.icon style={{ fontSize: "3rem", color: "white" }} />
                                </div>
                                <h3 className="fs-1 text-white">{card.heading}</h3>
                                <p className="text-white">{card.text}</p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default HomeCards;
