import React from 'react'
import Adopt from '../components/home/Adopt'
import HomeCards from '../components/home/HomeCards'
import HomeTestimonials from '../components/home/HomeTestimonials'
import MapsSection from '../components/home/MapsSection'
import DynamicHero from '../components/shared/DynamicHero'

const Home = () => {
  return (
    <>
      <DynamicHero
        upperheading={"What we do"}
        heading={<p className='display-4 text-uppercase'>Partner With Confidence</p>}
        description={"Truvest Capital Markets GMBH, a leading provider of fintech banking services and trade finance solutions, if you're looking for tailored banking services or reliable trade finance solutions, Truvest Capital Markets GMBH is committed to providing professional support and expertise. Trust in Truvest for all your financial needs."}
        home
      />
      <Adopt />
      {/* <Logos /> */}
      <HomeCards />
      <MapsSection />
      <HomeTestimonials />
      <div style={{ backgroundColor: "#F0F0F0" }} className="d-lg-block d-none py-5">

      </div>
    </>
  )
}

export default Home