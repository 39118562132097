import React from 'react'
import DynamicHero from '../components/shared/DynamicHero'
import { Card, Col, Container, Row } from 'react-bootstrap'
import PageHeading from '../components/shared/PageHeading'

const Services = () => {
  const cardsData = [
    { heading: "1. Secured Transactions", text: "Our Secured Transaction opportunities are offered only via invitation, to verified high net-worth individuals and corporations." },
    { heading: "2. Capital Contribution", text: "A Capital Contribution is the cash given to Truvest in exchange for an equity interest in an ongoing trade finance transaction, funding the transaction facilitation to a successful conclusion. A capital contributor is the named participant providing the cash to fund the transaction." },
    { heading: "3. financial instrument", text: "Each verified Capital Contributor may receive a Financial Instrument issued to their nominated bank account or held to order on their behalf, guaranteeing / covering 100 % of your Capital Contribution, depending on the underlying transaction. This means that at the end of the term of the transaction, if there is a delay or a potential risk of non-performance, our capital contributors will have an independent right to instruct their bank to debit the instrument being held, providing the return of their investment capital from the financial instrument issuing bank, making our capital contribution programme 100 % secure." },
  ];

  return (
    <>
      <DynamicHero
        heading={<p className="display-4 text-uppercase">Capital
          <br />
          Contribution</p>}
        small={"YOUR BENEFITS"}
        description={"Truvest Capital Market provides a flexible approach to ensure that clients receive the most appropriate solution"}
        service
      />
      <Container className="my-5">
        <PageHeading heading="services" />
        <h1 className="text-uppercase fs-4 color-primary service-card  mb-4">Capital Contribution</h1>

        <div className="col-lg-10 ps-lg-4 fs-6 mb-5 mx-auto">
          <p>What is a Truvest Capital Contributor?</p>
          <p>
            <strong className='px-2'>At Truvest we refer to our partners as capital contributors,</strong>
            because the term investor is not quite an accurate term.
          </p>
          <p>Due to the following:</p>
        </div>

        <Row className="g-4">
          {cardsData.map((card, index) => {
            const bgColor = index === 0 ? '#DCCBBA' : index === 1 ? '#253571' : '#D5D5D5';
            const color = index === 0 ? 'black' : index === 1 ? 'white' : 'black';

            return (
              <Col key={index} md={index === 2 ? 12 : 6} sm={12} className="d-flex p-3">
                <Card
                  className="p-4 w-100 border-0 shadow-sm d-flex justify-content-center"
                  style={{
                    backgroundColor: bgColor,
                    color: color,
                    minHeight: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div>
                    <h4 className="mb-3 text-uppercase fw-normal">{card.heading}</h4>
                    <p>{card.text}</p>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default Services;
