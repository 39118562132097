import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const ContactPreferencesForm = ({ formData, onInputChange }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        onInputChange(name, value);
    };

    return (
        <div>
            <h5 className="fw-bold mb-4">9. Contact Preferences</h5>

            <Row className="mb-4 align-items-center">
                <Col xs={12}>
                    <Form.Label>Preferred Method of Contact:</Form.Label>
                </Col>
                <Col xs={12} md={9} className="d-flex flex-wrap offset-md-3">
                    {["Email", "Phone", "Mail"].map((type) => (
                        <Form.Check
                            key={type}
                            type="radio"
                            name="CPpreferredMethod"
                            value={type}
                            label={type}
                            id={`CPpreferredMethod-${type}`}
                            checked={formData.CPpreferredMethod === type}
                            onChange={handleChange}
                            className="me-3"
                            required
                        />
                    ))}
                </Col>
            </Row>

            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Jurisdiction of Issuance</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                    <Form.Control
                        type="text"
                        placeholder="Enter Jurisdiction"
                        name="CPjurisdictionOfIssuance"
                        value={formData.CPjurisdictionOfIssuance || ""}
                        onChange={handleChange}
                        required
                    />
                </Col>
            </Row>
        </div>
    );
};

export default ContactPreferencesForm;
