import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PageHeading from '../components/shared/PageHeading';

const AdminBookingView = () => {
    const navigate = useNavigate();
    const handleBookingRedirect = () => {
        navigate('/booking');
    };

    return (
        <Container className="py-5">
            <PageHeading heading="Admin Booking View" />
            <h1 className="text-uppercase fs-4 color-primary service-card mb-4">
                View all bookings made by clients in your calendar.
            </h1>
            <Button
                onClick={handleBookingRedirect}
                style={{ backgroundColor: "#253571" }}
                className="mb-1"
            >
                Book an Appointment
            </Button>
            <div className="calendar-container mt-4">
                <iframe
                    src={`${process.env.REACT_APP_ADMIN_CALENDAR}&hl=en`}
                    style={{
                        border: 'solid 1px #777',
                        width: '100%',
                        height: '600px',
                    }}
                    frameBorder="0"
                    scrolling="no"
                    title="Admin Booking Calendar"
                ></iframe>
            </div>
        </Container >
    );
};

export default AdminBookingView;
