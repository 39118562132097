import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const BankingInformation = ({ formData, onInputChange }) => {
    return (
        <div>
            <h5 className="fw-bold mb-4">5. Banking Information</h5>

            {/* Bank Name */}
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Bank Name</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                    <Form.Control
                        type="text"
                        placeholder="Enter Bank Name"
                        value={formData.BankName || ""}
                        onChange={(e) => onInputChange("BankName", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            {/* Bank Address */}
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Bank Address</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                    <Form.Control
                        type="text"
                        placeholder="Enter Bank Address"
                        value={formData.BankAddress || ""}
                        onChange={(e) => onInputChange("BankAddress", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            {/* Account Number and IBAN */}
            <Row className="mb-4">

                <Col xs={12} md={6}>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <Form.Label>Account Number</Form.Label>
                        </Col>
                        <Col md={6}>
                            <Form.Control
                                type="number"
                                placeholder="Enter Account Number"
                                value={formData.BankAccountNumber || ""}
                                onChange={(e) => onInputChange("BankAccountNumber", e.target.value)}
                                required
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={6}>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <Form.Label>IBAN</Form.Label>
                        </Col>
                        <Col md={6}>
                            <Form.Control
                                type="number"
                                placeholder="Enter IBAN"
                                className="col-md-6"
                                value={formData.BankingIban || ""}
                                onChange={(e) => onInputChange("BankingIban", e.target.value)}
                                required
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* Account Type */}
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Account Type</Form.Label>
                </Col>
                <Col xs={12} md={9} className="d-flex flex-wrap">
                    {["Current", "Savings", "Other"].map((type) => (
                        <Form.Check
                            key={type}
                            type="radio"
                            name="BankAccountType"
                            value={type}
                            label={type}
                            id={type}
                            checked={formData.BankAccountType === type}
                            onChange={(e) => onInputChange("BankAccountType", e.target.value)}
                            className="me-3"
                            required
                        />
                    ))}
                </Col>
            </Row>
        </div>
    );
};

export default BankingInformation;
