import React from 'react';
import { Container } from 'react-bootstrap';
import Map from './Map';

const MapsSection = () => {
    return (

        <div style={{ backgroundColor: '#F0F0F0' }}>
            <Container className="position-relative pt-5">
                <Map />
                <div className='map-list mb-4'>
                    <div className='custom-list-item text-center'>
                        <div className='custom-bullet ' style={{ backgroundColor: '#42DACE' }}></div>
                        <span className='text-center' style={{ color: '#42DACE', fontSize: '1.25rem', fontWeight: 'bold' }}>
                            Office Locations
                        </span>
                    </div>
                    <div className='custom-list-item'>
                        <div className='custom-bullet' style={{ backgroundColor: '#B49F8B' }}></div>
                        <span style={{ color: '#B49F8B', fontSize: '1.25rem', fontWeight: 'bold' }}>
                            Transaction Locations
                        </span>
                    </div>
                </div>
            </Container>

        </div>
    );
};

export default MapsSection;
