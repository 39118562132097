import React from 'react'
import DynamicHero from '../components/shared/DynamicHero'
import { Col, Container, Image, Row } from 'react-bootstrap'
import PageHeading from '../components/shared/PageHeading'
import img1 from '../assets/transaction4.png'

const Transaction = () => {
    return (
        <>
            <DynamicHero
                heading={<p className="display-4 text-uppercase">Other GOODS</p>}
                transaction
            />
            <Container className="my-5">
                <PageHeading heading="Transactions" />
                <Row className="align-items-center gy-4 py-3">
                    <Col lg={6} md={12}>
                        <h1 className="text-uppercase fs-4 color-primary service-card mb-4">
                            Other GOODS !
                        </h1>

                        <p>We are committed to facilitating supply chain financing for a wide range of commodities, leveraging our strong financial position to optimize transactions and ensure the security of funding.</p>
                        <p>Our comprehensive strategy encompasses a range of goods supplied by our partners, including:</p>




                        <ul>
                            <li>Energy By-Products like Wood Pellets</li>
                            <li>Construction Materials</li>
                            <li>Steel and Steel Products</li>
                            <li>Road Associated Materials</li>
                            <li>Edible Oils (available in various grades and packaging upon request)</li>
                            <li>Grain Products such as Wheat (available upon request)</li>
                            <li>Sugar (including ICUMSA and EU Grades in both white and brown variants from different origins like EU, Brazil, and India)</li>
                            <li>Rice (offering different grades and origins upon request)</li>
                        </ul>



                        <p>To fortify our financing security measures, we implement similar transaction strategies for each commodity, including the issuance of financial instruments by purchasers, equity stakes in profits for our partners, and comprehensive credit insurance policies managed by reputable third-party brokers. This approach ensures that our partners are safeguarded while optimizing the efficiency and reliability of our global supply chain operations.</p>
                    </Col>
                    <Col lg={6} md={12} className="d-flex justify-content-center">
                        <Image fluid alt="Transaction Image" src={img1} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Transaction
