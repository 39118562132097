import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import bgImage from "../assets/landing.png";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh",
        width: "100%",
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(37, 53, 113, 0.6)",
          backdropFilter: "blur(8px)",
          zIndex: 1,
        }}
      />
      <Container
        fluid
        className="d-flex flex-column justify-content-center align-items-center text-white"
        style={{ position: "relative", zIndex: 2, minHeight: "100vh" }}
      >
        <Row className="text-center px-3">
          <h1 className="text-uppercase fw-bold my-3">Truvest Capital Markets</h1>
        </Row>
        <Col
          xl={6}
          lg={8}
          md={10}
          xs={11}
          className="mx-auto py-4 text-center"
        >
          <h2 className="text-uppercase fw-semibold mb-3">Commercial Finance</h2>
          <p className="px-2">
            Truvest Capital Markets excels in providing tailored Commercial
            Finance solutions, specializing in the management of a wide range of
            financial instruments, including Standby Letters of Credit (SBLCs),
            Bank Guarantees (BGs), Medium-Term Notes (MTNs), and other verified
            bank instruments.
          </p>
          <div className="d-flex flex-md-row flex-column justify-content-center gap-3 mt-3">
            <Link to="/issuance" className="btn-custom">
              Monetization
            </Link>
            <Link to="/issuance" className="btn-custom">
              Issuance
            </Link>
          </div>
          <section className="pt-md-5">
            <h2 className="text-uppercase fw-semibold mt-5">
              Fintech Banking
            </h2>
            <p className="px-2">
              Truvest Capital Markets supports FinTech Banking Clients with
              seamless solutions for managing their financial operations. We assist
              in opening specialized accounts designed to securely receive
              monetization proceeds, deposit issuance fees, and facilitate everyday
              banking needs.
            </p>
            <div className="d-flex flex-md-row flex-column justify-content-center gap-3 mt-4">
              <Link
                to="https://banking.truvest.de/en/PersonalRegister/Index"
                className="btn-custom"
              >
                Private Account
              </Link>
              <Link
                to="https://banking.truvest.de/en/CorporateRegister/Index"
                className="btn-custom"
              >
                Corporate Account
              </Link>
            </div>
          </section>
        </Col>
      </Container>

      <style jsx>{`
        .btn-custom {
          background-color: white;
          color: #253571;
          text-decoration: none;
          text-transform: uppercase;
          width:100%;
          font-size: 1.2rem;
          font-weight: 500;
          padding: 1rem 2rem;
          text-align: center;
          border: none;
          transition: all 0.3s ease;
        }

        .btn-custom:hover {
          background-color: #253571;
          color: white;
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
          transform: scale(1.05);
        }

        @media (max-width: 576px) {
          .btn-custom {
            font-size: 1rem;
            padding: 0.8rem 1.5rem;
          }

          h1, h2 {
            font-size: 1.5rem;
          }

          p {
            font-size: 0.9rem;
          }
        }
      `}</style>
    </div>
  );
};

export default LandingPage;
