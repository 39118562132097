import React from 'react'
import DynamicHero from '../components/shared/DynamicHero'
import { Card, Col, Container, Row } from 'react-bootstrap'
import PageHeading from '../components/shared/PageHeading'

const Services = () => {
    const cardsData = [
        { heading: "Function of Trade Finance", text: "Trade Finance facilitates international trade by providing financial tools like letters of credit and guarantees to mitigate risks, ensure payment, and support transactions between buyers and sellers globally." },
        { heading: "Function of Supply Chain Finance", text: "Supply Chain Finance optimizes working capital by enabling suppliers to receive early payments on invoices, improving cash flow for both suppliers and buyers through collaborative financial arrangements" },

    ];

    return (
        <>
            <DynamicHero
                heading={<p className="display-4 text-uppercase">International
                    <br />
                    trade financing</p>}
                small={"YOUR BENEFITS"}
                description={".Truvest Capital Market provides customized solutions for seamless international trade financing needs."}
                service
            />
            <Container className="my-5">
                <PageHeading heading="services" />
                <h1 className="text-uppercase fs-4 color-primary service-card  mb-4">International trade financing</h1>
                <div className=" fs-6 mb-5">
                    <p>
                        <strong className='pe-2'>
                            Our International Trade Financing Service provides Tailored Financial Solutions
                        </strong>
                        to assist businesses in managing the complexities of international trade. From letters of credit to trade finance solutions. we offer comprehensive support to facilitate smooth and secure transactions, to both the importer and exporter, but more importantly 100% security to our capital contributors - Our Partners.
                    </p>
                    <p>
                        The function of our trade finance service is to participate in a secure and verified buy and sell transactions, removing the payment / settlement risk and the supply / delivery risk.

                    </p>
                    <p>
                        Truvest provides the exporter with receivables or payment according to the agreement, while assisting the importer with logistic credit to receive and manage a controlled fulfillment of the trade order. Creating a secured transaction for the exporter and importer, with secured financial returns to our capital contributors.
                    </p>
                </div>

                <Row className="g-4">
                    {cardsData.map((card, index) => {
                        const bgColor = index === 0 ? '#DCCBBA' : index === 1 ? '#253571' : '#D5D5D5';
                        const color = index === 0 ? 'black' : index === 1 ? 'white' : 'black';

                        return (
                            <Col key={index} md={index === 2 ? 12 : 6} sm={12} className="d-flex p-3">
                                <Card
                                    className="p-4 w-100 border-0 shadow-sm d-flex justify-content-center"
                                    style={{
                                        backgroundColor: bgColor,
                                        color: color,
                                        minHeight: '300px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div>
                                        <h4 className="mb-3 text-uppercase fw-normal">{card.heading}</h4>
                                        <p>{card.text}</p>
                                    </div>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </>
    );
};

export default Services;
