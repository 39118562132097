    import React from 'react';
    import { Container } from 'react-bootstrap';
import PageHeading from '../components/shared/PageHeading';

    const CookiePolicy = () => (
        <Container className="mt-4 mb-5 pb-xl-5">
        

            <PageHeading heading="Cookies Policy" />

            <h2 className='card-title py-4 text-uppercase '>                1. What are cookies?</h2>
            <p>
                Cookies are small text files that are placed on your device (computer,
                smartphone, tablet) when you visit a website. They are widely used to
                improve the user experience, analyse website usage, and provide
                personalised content and advertisements.
            </p>
            <h2 className='card-title py-4 text-uppercase '>                2. Types of cookies we use</h2>
            <ul>
                <li>
                    <strong>Necessary Cookies:</strong> These cookies are essential for the
                    proper functioning of our website and enable you to navigate and use
                    its features. They help with tasks such as authenticating users,
                    maintaining secure sessions, and remembering your preferences.
                </li>
                <li>
                    <strong>Analytics Cookies:</strong> We use these cookies to collect
                    information about how visitors use our website. This data helps us
                    understand user behaviour, identify areas for improvement, and optimise
                    our website’s performance. The information collected is aggregated and
                    anonymised.
                </li>
                <li>
                    <strong>Functional Cookies:</strong> These cookies allow our website to
                    remember choices you make and provide enhanced features, such as
                    personalised settings or saved preferences.
                </li>
                <li>
                    <strong>Advertising and Tracking Cookies:</strong> We may work with
                    third-party advertisers and partners who use cookies to display
                    relevant advertisements and track their effectiveness. These cookies
                    may also collect information about your online activities over time and
                    across different websites.
                </li>
                <li>
                    <strong>Social Media Cookies:</strong> Our website may include social
                    media sharing buttons or widgets that allow you to interact with
                    various social media platforms. These buttons may set cookies to track
                    your interactions on those platforms.
                </li>
            </ul>
            <h2 className='card-title py-4 text-uppercase '>                
                3. Managing cookies</h2>
            <p>
                You can manage cookies through your browser settings. Here’s how:
            </p>
            <ul>
                <li>
                    <strong>Cookie Preferences:</strong> Most browsers allow you to control
                    cookies through their settings. You can usually find these settings in
                    the “Options” or “Preferences” menu of your browser. Different browsers
                    may use different methods to disable cookies, but you can usually find
                    these options under the “Privacy” or “Security” section.
                </li>
                <li>
                    <strong>Opt-Out of Tracking:</strong> You can opt-out of certain types
                    of tracking cookies by visiting the websites of relevant third-party
                    advertisers or using third-party opt-out tools.
                </li>
                <li>
                    <strong>Do Not Track (DNT):</strong> Some browsers offer a “Do Not
                    Track” feature that signals to websites that you do not want to be
                    tracked across websites. This feature is not universally supported, and
                    its effectiveness is not guaranteed.
                </li>
            </ul>
            <p>
                Please note that disabling or blocking certain cookies may impact the
                functionality and performance of our website or limit your access to
                certain features.
            </p>
        </Container>
    );

    export default CookiePolicy;
