import React, { useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import AdditionalInfoForm from './AdditionalInfoForm';
import BankingInformation from './BankingInformation';
import CompilanceForm from './CompilanceForm';
import ContactPreferencesForm from './ContactPreferencesForm';
import ContactUsForm from './ContactUsForm';
import EmploymentForm from './EmploymentForm';
import FIDForm from './FIDForm';
import IdentificationForm from './IdentificationForm';
import PersonalInformationForm from './PersonalInformationForm';
import SignatureForm from './SignatureForm';
import SupportinDocsForm from './SupportinDocsForm';

const ResponsiveForm = () => {
     
    const [formData, setFormData] = useState({});

    const handleInputChange = (key, value) => {
        setFormData((prev) => ({
            ...prev,
            [key]: value,
        }));
    };
    const array = [
        formData?.SupportingDocsProofOfAddress,
        formData?.SupportingDocsFinancialStatements,
        formData?.SupportingDocsBusinessPlan,
        formData?.IdentificationDocsFile1,
        formData?.IdentificationDocsFile2,
        formData?.FinancialDocs,
        formData?.digitalSignatureFile


    ]


    const handleSubmit = async(e) => {
        e.preventDefault();

        const fileLabels = [
            "Proof of Address",
            "Financial Statements",
            "Business Plan",
            "Primary Identification Document",
            "Secondary Identification Document",
            "Financial Documents",
            "Digital Signature",
        ];

        const missingFiles = array
            .map((file, index) => (!file ? fileLabels[index] : null))
            .filter((file) => file !== null);

        if (missingFiles.length > 0) {
            Swal.fire({
                icon: "error",
                title: "Missing Files",
                html: `<ul style="text-align: left;">${missingFiles
                    .map((file) => `<li>${file}</li>`)
                    .join("")}</ul>`,
            });
            return;
        }

        Swal.fire({
            icon: "success",
            title: "Submission Successful",
            text: "All required files have been uploaded successfully!",
        });
       
    };


  


    return (
        <Container className="my-5 issuance-form">
            <Form onSubmit={handleSubmit}>
                <PersonalInformationForm formData={formData} onInputChange={handleInputChange} />
                <ContactUsForm formData={formData} onInputChange={handleInputChange} />
                <IdentificationForm formData={formData} onInputChange={handleInputChange} />
                <EmploymentForm formData={formData} onInputChange={handleInputChange} />
                <BankingInformation formData={formData} onInputChange={handleInputChange} />
                <FIDForm formData={formData} onInputChange={handleInputChange} />
                <CompilanceForm formData={formData} onInputChange={handleInputChange} />
                <SupportinDocsForm formData={formData} onInputChange={handleInputChange} />
                <ContactPreferencesForm formData={formData} onInputChange={handleInputChange} />
                <AdditionalInfoForm formData={formData} onInputChange={handleInputChange} />
                <SignatureForm formData={formData} onInputChange={handleInputChange} />


                <div className="d-flex justify-content-end py-5 ">
                    <button type="submit" className="btn btn-primary submit">
                        Submit
                    </button>
                </div>
            </Form>
        </Container>
    );
};

export default ResponsiveForm;
