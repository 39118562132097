import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
const Adopt = () => {
    const services = [
        {
            title: 'Trade Finance',
            text: 'Our Trade Finance Solutions are available worldwide contingent in the issuance and/or monetization of financial instruments.'
        },
        {
            title: 'Personal & Corporate Banking',
            text: 'We offer easy access to both traditional and new fintech banking products.'
        },

        {
            title: 'Trade Finance Private Advisory',
            text: 'We provide expert guidance and strategic advice to corporations and private high net worth individuals, ',
            long: true
        },

    ];
    return (
        <>
            <div className="hero  py-2">
                <Container className=' py-3'>
                    <Row>
                        <Col xs={12} lg={6} className='d-flex align-items-center'>
                            <div className='text-center text-lg-start  p-lg-5 p-2'>
                                <p className='top-text pt-lg-0 pt-4 ' style={{ color: "var(--primary-color)" }}>PRACTICE AREAS</p>
                                <h1 className='py-2'>ADOPT FLEXIBLE APPROACHES FOR CLIENTS</h1>

                            </div>
                        </Col>
                        <Col xs={12} lg={6} className='d-flex align-items-center'>
                            <div className='text-center text-lg-start  p-lg-5 p-2'>
                                <p className='fs-5'>With a strong focus on Customer Satisfaction, Security and Financial Innovation, we strive to deliver Tailored Solutions to meet the unique needs of our clients in the Ever-Evolving Global Market.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {services.map((service, index) => (
                            <Col xs={12} className='mb-4' xl={4} key={index}>
                                <Card
                                    style={{
                                        backgroundColor:
                                            index === 0 ? "#00BEC0" : index === 1 ? "#1A7CD6" : "black",
                                    }}
                                    className="text-white border-0 my-3 p-4 h-100"
                                >
                                    <Card.Body className="d-flex flex-column">
                                        <div>
                                            <Card.Title className="pb-2 text-uppercase fs-5">
                                                {service.title}
                                            </Card.Title>
                                            <Card.Text className="text-white">{service.text}</Card.Text>
                                        </div>
                                        {/* <div className="mt-auto mt-5 pt-5">
                                            <div className="row">
                                                <Link to='#' className="col-2 offset-10">
                                                    <Plus />
                                                </Link>
                                            </div>
                                        </div> */}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    {/* <Row className='py-4 text-end'>
                        <a href="#" className='text-decoration-none link-hover text-dark fs-5'>See in detail</a>
                    </Row> */}
                </Container>
            </div>
        </>
    )
}

export default Adopt