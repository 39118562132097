import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import DynamicHero from '../components/shared/DynamicHero'
import PageHeading from '../components/shared/PageHeading'
import TransactionsCard from '../components/TransactionCards'

const Transaction = () => {
    return (
        <>
            <DynamicHero
                heading={<p className="display-4 text-uppercase">Precious Metals</p>}
                transaction
            />
            <Container className="my-5">
                <PageHeading heading="Transactions" />
                <Row className="align-items-center gy-4 py-3 mb-5">
                    <Col md={12}>
                        <h1 className="text-uppercase fs-4 color-primary service-card mb-4">
                            Precious Metals
                        </h1>
                        <p>
                            Truvest have extensive expertise in all aspects of the physical commodity trading markets. Partnering with clients to facilitate commodity trades for verified SMEs
                            along with our contractual counterparties, we assist in the logistic operations and physical deliveries' of the following:
                        </p>
                        <TransactionsCard />
                        <p>Participation in such Lucrative Transactions provides access to regular, consistent returns on Capital Contributions, backed by ring-fenced security of proceeds from Financial Instruments, Safeguarding underlying transactions and associated contributions.</p>
                    </Col>

                </Row>
            </Container>
        </>
    )
}

export default Transaction
