import React, { useRef, useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

const testimonials = [
    {
        name: 'Sandra Harrington',
        text: 'Participating with Truvest has been a great experience. With 100% security and solid returns, their process is always smooth and transparent.',
        role: 'CEO, Example Inc.',
    },
    {
        name: 'Raul Zenon',
        text: 'Truvest made participating easy and secure. The returns have been great, and the whole experience has been transparent and hassle-free.',
        role: 'Manager, Tech Solutions',
    },
    {
        name: 'Elekta Gulf',
        text: 'Truvest helped us close key trade deals quickly and securely, supporting our operations with their fantastic financial instrument issuance, assisting every step of the way.',
        role: 'Freelancer',
    },
];

const HomeTestimonials = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef(null);

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };

    return (
        <Container className="py-5">
            <p className="color-primary fs-3 fw-light mb-4 ">
                Client Testimonials
            </p>
            <Col className="col-lg-6 mx-auto">
                <Swiper
                    modules={[Pagination,Autoplay]}
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                    onSlideChange={handleSlideChange}
                    autoplay
                    spaceBetween={30}
                    slidesPerView={1}
                >
                    {testimonials.map((testimonial, index) => (
                        <SwiperSlide key={index}>
                            <div
                                className="py-4 pe-3 border-start-0"
                                style={{ border: '1px solid  #253571' }}
                            >
                                <div className="">
                                    <p className="fs-5 mb-3 text-dark">
                                        "{testimonial.text}"
                                    </p>
                                    <h5 className="color-primary fw-light">{testimonial.name}</h5>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

                <div className="d-flex  mt-3">
                    {testimonials.map((_, index) => (
                        <button
                            key={index}
                            className={`mx-1 p-1 rounded-circle ${index === activeIndex
                                ? 'bg-custom text-white'
                                : 'bg-custom-off text-dark'
                                }`}
                            style={{
                                width: '12px',
                                height: '12px',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => swiperRef.current?.slideTo(index)}
                        ></button>
                    ))}
                </div>
            </Col>
        </Container>
    );
};

export default HomeTestimonials;
