import React from 'react'
import { Container } from 'react-bootstrap'
import PageHeading from '../components/shared/PageHeading'
import IssuanceForm from '../components/IssuanceForm'

const Issuance = () => {
    return (
        <div  style={{
            backgroundColor: "#FFFFFF"
        }} >

        <Container className='py-5'>
            <PageHeading heading="Issuance" />
            <p className='mb-4'>To facilitate the issuance of a financial instrument through Truvest Capital Market, clients are required to provide comprehensive information to ensure compliance with regulatory standards and to tailor services effectively. Below is a detailed web form outlining the necessary fields:</p>
            <h1 className="text-uppercase fs-4 color-primary service-card  mb-4">Client Information Form for Financial Instrument Issuance </h1>

            <IssuanceForm/>
        </Container>
                </div>
    )
}

export default Issuance