import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const EmploymentForm = ({ formData, onInputChange }) => {
    return (
        <div>
            <h5 className="fw-bold mb-4">4. Employment and Financial Information</h5>

            {/* Employment Status */}
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Employment Status</Form.Label>
                </Col>
                <Col xs={12} md={9} className="d-flex flex-wrap">
                    {["Employed", "Self-Employed", "Unemployed", "Retired", "Student"].map((status) => (
                        <Form.Check
                            key={status}
                            type="radio"
                            name="EmploymentType"
                            value={status}
                            label={status}
                            id={status}
                            checked={formData.EmploymentType === status}
                            onChange={(e) => onInputChange("EmploymentType", e.target.value)}
                            className="me-3"
                            required
                        />
                    ))}
                </Col>
            </Row>

            {/* Occupation */}
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Occupation</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                    <Form.Control
                        type="text"
                        placeholder="Enter Occupation"
                        value={formData.EmploymentOccupation || ""}
                        onChange={(e) => onInputChange("EmploymentOccupation", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            {/* Employee's Name */}
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Employee’s Name</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                    <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={formData.EmploymentName || ""}
                        onChange={(e) => onInputChange("EmploymentName", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            {/* Employee's Address */}
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Employee’s Address</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                    <Form.Control
                        type="text"
                        placeholder="Enter Address"
                        value={formData.EmploymentAddress || ""}
                        onChange={(e) => onInputChange("EmploymentAddress", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            {/* Annual Income */}
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Annual Income</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                    <Form.Control
                        type="text"
                        placeholder="Enter Annual Income"
                        value={formData.EmploymentAnnualIncome || ""}
                        onChange={(e) => onInputChange("EmploymentAnnualIncome", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            {/* Source of Funds */}
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Source of Funds</Form.Label>
                </Col>
                <Col xs={12} md={9} className="d-flex flex-wrap">
                    {["Salary", "Business Income", "Investment", "Other"].map((source) => (
                        <Form.Check
                            key={source}
                            type="radio"
                            name="EmploymentSourceOfFunds"
                            value={source}
                            label={source}
                            id={source}
                            checked={formData.EmploymentSourceOfFunds === source}
                            onChange={(e) => onInputChange("EmploymentSourceOfFunds", e.target.value)}
                            className="me-3"
                            required
                        />
                    ))}
                </Col>
            </Row>

            {/* Estimated Net Worth */}
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Estimated Net Worth</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                    <Form.Control
                        type="text"
                        placeholder="Enter Estimated Net Worth"
                        value={formData.EmploymentNetWorth || ""}
                        onChange={(e) => onInputChange("EmploymentNetWorth", e.target.value)}
                        required
                    />
                </Col>
            </Row>
        </div>
    );
};

export default EmploymentForm;
