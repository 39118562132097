import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";

import icon1 from "../assets/goldbars.png";
import icon2 from "../assets/goldcoins.png";
import icon3 from "../assets/silverbars.png";
import icon4 from "../assets/silvercoins.png";
import icon5 from "../assets/platinum.png";
import icon6 from "../assets/palaldium.png";
import icon7 from "../assets/copper.png";
import icon8 from "../assets/rareearth.png";

const TransactionsCard = () => {
    const cards = [
        { id: 1, src: icon1, heading: "Gold Bars", text: "1g, 10g, 20g, 31.1g, 100g, 1000g, 12,5kg" },
        { id: 2, src: icon2, heading: "Gold Coins", text: "1/10 oz, 1/4 oz, 1/2 oz, 1Oz" },
        { id: 3, src: icon3, heading: "Silver Bars", text: "31.1g, 100g, 250g, 500g, 1000g, 5000g, 15000g" },
        { id: 4, src: icon4, heading: "Silver Coins", text: "per request" },
        { id: 5, src: icon5, heading: "Platinum", text: "per request" },
        { id: 6, src: icon6, heading: "Palladium", text: "per request" },
        { id: 7, src: icon7, heading: "Copper", text: "isotopic copper powder, copper sheets, copper cathodes" },
        { id: 8, src: icon8, heading: "Rare earth", text: "various elements" },
    ];

    const bgColors = [
        "#FFBC00",
        "#FFD952",
        "#A6A6A6",
        "#D9D9D9",
        "linear-gradient(90deg, #D9D9D9 0%, #AEAFB4 100%)",
        "linear-gradient(90deg, #D9D9D9 0%, #C2C2C2 50.5%, #E7E7E7 85%)",
        "#BB773A",
        "#F8DBBF",
    ];

    return (
        <div className="client-logo-slider py-5">
            <Swiper
                modules={[Autoplay]}
                spaceBetween={20}
                slidesPerView={2}
                loop={true}
                autoplay={{ delay: 2000 }}
                breakpoints={{
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 6,
                        spaceBetween: 20,
                    },
                    1440: {
                        slidesPerView: 7,
                        spaceBetween: 25,
                    },
                }}
            >
                {cards.map((card, index) => (
                    <SwiperSlide key={index}>
                        <div
                            className="p-3 rounded text-center d-flex flex-column align-items-center justify-content-center"
                            style={{
                                background: bgColors[index % bgColors.length],
                                height: "200px",
                                width: "100%",
                            }}
                        >
                            <img
                                src={card.src}
                                alt={card.heading}
                                className="img-fluid mb-2"
                                style={{ maxHeight: "80px", objectFit: "contain" }}
                            />
                            <h6 className="mb-1">{card.heading}</h6>
                            <p className="mb-0 small text-muted">{card.text}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default TransactionsCard;
