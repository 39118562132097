import React from 'react'
import DynamicHero from '../components/shared/DynamicHero'
import { Card, Col, Container, Row } from 'react-bootstrap'
import PageHeading from '../components/shared/PageHeading'

const Services = () => {
    const cardsData = ["Our banking platform is very easy to navigate, you can view your account balances, transaction history, and statements, and even set up direct debits, standing orders and custom alerts to help you stay on top of your finances.", "Be in complete control over your money with our online banking platform, protected with the latest encryption technology and multi-factor authentication, giving you peace of mind when managing your finances.", " Whether you are at home, at work, on the go, participating in lucrative trade finance transactions or your own private banking needs, our online banking platform is accessible from your computer, tablet, or mobile device. Virtual or Physical Cards ⎯ it’s your choice, or why not have both.", "Accept payments from customers around the world directly to your account on our online banking platform and easily and securely transfer funds around the world at competitive rates. Link your Bank account to your online banking for seamless and secure online payments."

    ];

    return (
        <>
            <DynamicHero
                heading={<p className="display-4 text-uppercase">Contributors & Truvest
                    <br />
                    Fintech Banking Services</p>}
                small={"YOUR BENEFITS"}
                description={"Truvest Capital Market offers tailored fintech banking solutions for Private and Corporate partners."}
                service
            />
            <Container className="my-5">
                <PageHeading heading="services" />
                <h1 className="text-uppercase fs-4 color-primary service-card  mb-4">Contributors & Truvest Fintech Banking Services</h1>

                <div className=" fs-6 mb-5">
                    <p>
                        <strong className='px-2'>Truvest operate a bespoke fintech platform.</strong>
                        Our online banking platform is user-friendly, designed for our client to utilize for depositing their contributions into their own online account and receiving the agreed profits, as well as any other standard banking services you wish to use your account for.
                    </p>
                </div>

                <Row className="g-4">
                    {cardsData.map((card, index) => {
                        const bgColor = index === 0 ? '#D2B48C' : index === 1 ? '#253571' : index === 2 ? "#E9E1DB " : '#A2A2A2';
                        const color = index === 0 ? 'black' : index === 1 ? 'white' : index === 2 ? "black" : 'white';
                        return (
                            <Col key={index} lg={3} md={6} sm={12} className="d-flex p-xxl-5 p-lg-3 p-2">
                                <Card
                                    className="p-4 w-100 border-0 shadow-sm d-flex justify-content-center"
                                    style={{
                                        backgroundColor: bgColor,
                                        color: color,
                                        minHeight: '300px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <div className='d-flex gap-2'>
                                        <p>{index + 1}</p>
                                        <p>{card}</p>
                                    </div>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </>
    );
};

export default Services;
