import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/global.css"
import Header from './components/shared/Header';
import { BrowserRouter, useLocation } from 'react-router-dom';
import Footer from './components/shared/Footer';
import 'react-toastify/dist/ReactToastify.css';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "react-datepicker/dist/react-datepicker.css";
import "react-country-state-city/dist/react-country-state-city.css";
import 'react-phone-number-input/style.css'


const AppWrapper = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/login' && location.pathname !== '/forgetpassword' && location.pathname !== '/getstarted' && <Header />}
      <App />
      {
        location.pathname !== "/getstarted" && <Footer />
      }
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <AppWrapper />
    </React.StrictMode>
  </BrowserRouter>
);
